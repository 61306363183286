import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { SongConsumer } from '../components/context';
import Layout from '../components/layout';
import AudioControl from '../components/audio-control';
import '../assets/styles/pages/edition-single.scss';

const Edition = ({ data, pageContext }) => {
  const edition = data.datoCmsEdition;
  return (
    <SongConsumer>
      {(context) => (
        <Layout pagination={pageContext} paginate="editions">
          <HelmetDatoCms seo={edition.seoMetaTags} />
          <div className="container">
            <div className="edition-single row">
              <div className="one-quarter sidebar">
                {edition.trackId}
                <br />
                <h1>{edition.name}</h1>
                <strong><em>{edition.title}</em></strong>
                <br />
                <AudioControl edition={edition} context={context} />
                <br />
                {edition.date}
              </div>
              <div className="two-quarters">
                {edition.trackImage && edition.trackImage.filename.split('.').pop() !== 'svg' ? (
                  <Img fluid={{ ...edition.trackImage.fluid, aspectRatio: 2 / 2 }} />
                ) : (
                  <img src={edition.trackImage.url} alt={edition.title} />
                )}
                <div
                  className="edition-body-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: edition.body,
                  }}
                />
                {edition.artistNotes && (
                  <div className="edition-artistnotes-wrapper">
                    <div className="bold margin-top"><strong>Artist notes:</strong></div>
                    <div
                      className="edition-artistnotes"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: edition.artistNotes,
                      }}
                    />
                  </div>
                )}
                {edition.productionInfo && (
                  <div
                    className="edition-productioninfo-wrapper margin-top"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: edition.productionInfo,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </SongConsumer>
  );
};

export default Edition;

export const query = graphql`
  query EditionQuery($slug: String!) {
    datoCmsEdition(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      trackImage {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsSizes
        }
        url
        filename
      }
      trackId
      title
      name
      date
      body
      artistNotes
      productionInfo
      soundFile
    }
  }
`;

Edition.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};
